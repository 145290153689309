<template>
  <div class="auth-wrapper auth-v2 bg-white">
    <b-row class="auth-inner m-0">

      <!-- Left Text-->
      <b-col
        lg="6"
        class="d-none d-lg-flex align-items-center"
        :style="`background-image: url(${sideImg});background-size: cover;background-position: 50%;border-radius: 0px 32px 32px 0px;`"
      >
        <div class="left--side__wrapper">
          <div class="wrapper__side">
            <div>
              <h1 class="text-white size20">
                Toqoo
              </h1>
              <hr
                class="w-25 m-0"
                style="border: 1px solid #E79242;"
              >
              <div class="mt-3">
                <h5 class="size18 fw-bold-400 mb-2">
                  We are
                </h5>
                <h6
                  class="size14 fw-bold-400"
                  style="line-height: 23px;"
                >
                  There are many variations of passages of Lorem <br>
                  Ipsum available, but the majority have suffered <br>
                  alteration in some form, by injected humour
                </h6>
              </div>
            </div>
            <div>
              <h5 class="size14 fw-bold-400 mb-1">
                Coba toqoo ?
              </h5>
              <h5 class="size18 fw-bold-600">
                Daftar Sekarang
              </h5>
            </div>
          </div>
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="6"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="10"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            title-tag="h2"
            class="fw-bold-800 mb-1 text-dark size20"
          >
            👋🏼 Halo, Selamat Datang
          </b-card-title>
          <b-card-text class="mb-2 text-dark text-darken-4 size14 mb-md-5 mb-0">
            Login terlebih dahulu untuk bisa akses web toqoo
          </b-card-text>

          <!-- form -->
          <validation-observer ref="loginValidation">
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent
            >
              <!-- email -->
              <b-form-group class="custom__form--input mb-2">
                <label for="email">Email <span class="text-danger">*</span></label>
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-input
                    id="email"
                    v-model="userEmail"
                    name="login-email"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Email"
                    autofocus
                  />
                  <small class="text-danger pt-1">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group class="custom__form--input mb-3">
                <label for="login-password">Password <span class="text-danger">*</span></label>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="password"
                      v-model="password"
                      :type="passwordFieldType"
                      class="form-control-merge"
                      :state="errors.length > 0 ? false:null"
                      name="login-password"
                      placeholder="Password"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="secondary"
                block
                :disabled="isLoading"
                class="size14 py-1 border-8 d-flex justify-content-center align-items-center"
                @click="validationForm"
              >
                <div
                  v-if="isLoading == true"
                  class="d-flex justify-content-center mr-1"
                >
                  <b-spinner
                    small
                    label="Loading..."
                  />
                </div>
                Login
              </b-button>
            </b-form>
          </validation-observer>

          <b-card-text class="text-center mt-2">
            <span>Jika ada kendala terkait login bisa </span>
            <b-link :to="'#'">
              <span class="text-dark text-darken-6">&nbsp;hubungi admin</span>
            </b-link>
          </b-card-text>
        </b-col>
      </b-col>
    <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroup, BCardText, BCardTitle, BForm, BButton, BSpinner,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BCardText,
    BCardTitle,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BSpinner,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      password: '',
      userEmail: '',
      sideImg: require('@/assets/images/pages/login_background-2.png'),
      // validation rulesimport store from '@/store/index'
      required,
      email,
      isLoading: false,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    validationForm() {
      this.$refs.loginValidation.validate().then(success => {
        this.isLoading = true
        if (success) {
          useJwt.login({
            email: this.userEmail,
            password: this.password,
          })
            .then(response => {
              const userPermission = JSON.stringify(response.data.data.permission)
              localStorage.setItem('userPermission', userPermission)
              const { token } = response.data.data
              useJwt.setToken(token)
              if (response.data.data) {
                const userData = JSON.stringify(response.data.data.user)
                localStorage.setItem('userData', userData)
                this.isLoading = false
                this.$router.push('/')
              } else {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Access Denied!',
                    icon: 'DangerIcon',
                    variant: 'danger',
                    text: 'You dont have access to this area!',
                  },
                })
                localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
                this.isLoading = false
                this.$router.push({ name: 'login' })
              }
            }).catch(() => {
              this.isLoading = false
            })
          setTimeout(() => {
            this.isLoading = false
          }, 500)
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Medula+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro:wght@400;600&family=Medula+One&display=swap');
.image__cover--login {
  border-radius: 0px 32px 32px 0px;
  object-fit: cover;
  height: 100vh;
}
.left--side__wrapper {
  position: absolute;
  left: 45%;
  top: 15%;
  transform: translate(-50%);
  .wrapper__side {
    display: flex;
    flex-direction: column;
    height: 70vh;
    justify-content: space-between;

    h1 {
      font-family: 'Medula One', sans-serif !important;
      font-size: 50px;
    }

    h5, h6 {
      color: #fff;
      font-family: 'Be Vietnam Pro', sans-serif !important;
    }
  }
}
</style>
